.btn-outline-primary {
  border: 2px solid #f8ef75 !important;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.btn-outline-primary:hover {
  background-color: transparent !important;
}

.page-header nav {
  font-family: Arial, Helvetica, sans-serif;
}

.page-header a {
  color: white;
}

.page-header a:hover {
  -webkit-text-decoration: #f8e875 underline;
  text-decoration: #f8e875 underline;
}

.name {
  font-size: 1.75rem;
  font-family: "Staatliches", sans-serif;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.home h1:first-of-type {
  font-family: "Staatliches";
  font-size: 80px;
  animation: fadein 1s forwards;
  opacity: 0;
}

.home h1:last-of-type {
  font-family: "Righteous", cursive;
  font-size: 64px;
  animation: fadein 1s 1s forwards;
  opacity: 0;
}

.home hr {
  border: 2px solid #f8ef75;
  min-width: 300px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.home h2 {
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 0;
}

.home a:first-of-type {
  color: white;
  font-size: 2.5rem;
}

.last-fade {
  animation: fadein 1s 2s forwards;
  opacity: 0;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.projects {
  color: white;
}

@keyframes expand {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.project-animate:nth-of-type(1) {
  animation: expand 0.23s 0.5s;
}

.project-animate:nth-of-type(2) {
  animation: expand 0.23s 0.6s;
}

.project-animate:nth-of-type(3) {
  animation: expand 0.23s 0.7s;
}

.project-animate:nth-of-type(4) {
  animation: expand 0.23s 0.8s;
}

.project-animate:nth-of-type(5) {
  animation: expand 0.23s 0.9s;
}

.project-link:hover {
  text-decoration: none;
}

.houdini-title {
  font-family: "Lobster", cursive;
  color: #b795e1;
}

.daze-title {
  color: white;
}

.golf-sucks-title {
  font-family: "Fugaz One", Impact, Haettenschweiler, "Arial Narrow Bold",
    sans-serif;
}

.dexter-title {
  font-family: "Luckiest Guy", cursive;
  color: #3d7dca;
}

.dexter-title span:nth-of-type(2) {
  color: #ffcb05;
}

.bernstein-blaster-title {
  font-family: "Press Start 2P", sans-serif;
  color: #fe2dc2;
}

@media screen and (max-width: 768px) {
  .home h1:first-of-type {
    font-size: 65px;
  }

  .home h1:last-of-type {
    font-size: 45px;
  }

  .bernstein-blaster-title {
    font-size: 2rem;
  }
}
