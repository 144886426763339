html {
  background-color: #1e2433;
}

body {
  background-color: transparent;
}

.home {
  min-height: 60vh;
}
